.PlantItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

.PlantImageDiv {
    height: 300px;
    width: 100%;
    max-width: 550px;
}

.PlantImage {
    height: 250px;
    width: auto;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.PlantImageLogo {
    height: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 100%;
    max-width: 550px;
}

.each-slide > img {
    height: 250px;
    width: auto;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.InfoItem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 20%;
    max-width: 900px;
    margin-bottom: 30px;
}

.EventPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

.EventInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    min-height: 20%;
    max-width: 900px;
    margin-bottom: 30px;
    padding-top: 20px;
    position: relative;
}

.EventsBar {
    width: 80%;
    height: 200px;
    text-align: center;
}

.MapsElement {
    height: 200px;
    width: 90%;
    border-radius: 5px;
}

.filepond--credits {
  opacity: 0 !important;
}
