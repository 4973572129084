.Dashboard {
    width: 100%;
    height: 100%;
}

.Overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
}

.AddPlant {

}

.PlantsInner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 90vh;
    padding-top: 70px;
}

.PlantListTemplate {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 20%;
    max-width: 900px;
    margin-bottom: 30px;
}

.filepond--credits {
  opacity: 0 !important;
}
